<script setup>
 // Composables
 import { focusOn } from '@/composables/helpers/client-only/focusOn';

 // Variables
 const { $bus } = useNuxtApp();
 const dropDownOpen = ref(false);

 // State
 const cart = useState('cart', () => false);

 // Computed
 const count = computed(() => {
  if (!cart.value) return 0;

  const items = cart.value?.cart?.cartItems;
  return items.reduce((acc, item) => acc + item.quantity, 0);
 });

 const isAugmenting = computed(() => {
  if (!cart.value) return true;

  return cart.value?.augmenting;
 });

 const total = computed(() => {
  if (!cart.value) return '$0';

  const items = cart.value?.cart?.cartItems;
  const total = items.reduce((acc, item) => acc + item.price * item.quantity, 0);
  return total.toLocaleString('en-US', {
   style: 'currency',
   currency: 'USD',
  });
 });

 const cartItems = computed(() => {
  if (!cart.value) return [];

  return cart.value?.cart?.cartItems;
 });

 // Methods
 const toggleDropdown = () => {
  if (dropDownOpen.value) closeDropdown();
  else openDropdown();
 };

 const openDropdown = (autoClose = false) => {
  $bus.emit('scroll-lock');
  dropDownOpen.value = true;

  window.addEventListener('keydown', (e) => {
   if (e.key === 'Escape') closeDropdown();
  });

  if (autoClose) {
   setTimeout(() => {
    closeDropdown();
   }, 5000);
  }
 };

 const closeDropdown = () => {
  $bus.emit('scroll-unlock');
  dropDownOpen.value = false;
  window.removeEventListener('keydown', (e) => {
   if (e.key === 'Escape') closeDropdown();
  });
 };

 // Lifecycle
 onMounted(() => {
  $bus.on('close-cart-dropdown', () => {
   closeDropdown();
  });

  $bus.on('open-cart-dropdown', () => {
   openDropdown(true);
  });
 });
</script>
<template>
 <div class="cart-dropdown">
  <a href="https://cart.architecture.org/cart/details" class="cart-button">
   <ProjectSvg type="cart" />
  </a>
  <!-- <button class="cart-button" @click="toggleDropdown">
   <span class="counter-container">
    <ClientOnly>
     <span class="counter fs-10"> {{ count }} </span>
     <template #fallback>
      <span class="counter fs-10"> 0 </span>
     </template>
    </ClientOnly>
   </span>
   <ProjectSvg type="cart" />
  </button>
  <Transition name="testing">
   <div v-hidden="!dropDownOpen" class="dropdown v-hidden" @click.self="closeDropdown">
    <div class="dropdown-wrapper">
     <div class="dropdown-wrapper-content">
      <button @click="closeDropdown" class="close">
       <span class="sr-only">Close</span>
       <ProjectSvg type="x" />
      </button>

      <h2 class="h4 title">Added to cart</h2>

      <Transition name="fade">
       <div v-if="!cart" class="empty-cart base-conditional">
        <p>There are no items in your cart</p>
       </div>
       <div v-else-if="isAugmenting" class="loading base-conditional">
        <MessLoading />
       </div>
       <ul class="cart-items base-conditional" v-else>
        <li v-for="item in cartItems" :key="item.uid">
         <h3 class="h5 item-title" v-html="item['mess-title']" />
         <p class="date"><strong class="h6">DATE: </strong> {{ item['mess-date'] }}</p>
         <p class="time"><strong class="h6">TIME: </strong> {{ item['mess-time'] }}</p>
         <p class="type">
          <strong class="h6">TICKET TYPE: </strong>{{ item['mess-price'] }} {{ item['mess-ticket-label'] }}
         </p>
         <p class="quantity"><strong class="h6">QTY: </strong> {{ item.quantity }}</p>
        </li>
       </ul>
      </Transition>

      <div class="subtotal">
       <h4 class="h5">Subtotal:</h4>
       <span v-html="total" />
      </div>

      <div class="actions">
       <ProjectButton label="View Cart" link="https://cart.architecture.org/cart/details" />
       <ProjectButton label="Checkout" link="https://cart.architecture.org/cart/payment" version="red-fill" />
      </div>
      <button class="sr-only sr-only-focusable" @click="closeDropdown" id="close-cart-dropdown-button">
       Close cart dropdown
      </button>
      <span tabindex="0" class="sr-only sr-only-focusable" @focus="focusOn('close-cart-dropdown-button')"
       >Must close dropdown before leaving focus</span
      >
     </div>
    </div>
   </div>
  </Transition> -->
 </div>
</template>

<style lang="scss">
 .cart-dropdown {
  position: relative;

  --padding: 2.5rem;
  --close-button-size: 1.125rem;

  .dropdown {
   position: fixed;
   right: 0;
   transition: 400ms ease-in-out;

   opacity: 1;

   &.v-hidden {
    opacity: 0;
    pointer-events: none;

    .dropdown-wrapper {
     padding: 0 var(--padding);
     grid-template-rows: 0fr;
     transition: 400ms ease-in-out;
    }
   }

   &:before {
    content: '';
    position: absolute;
    top: 0;
    right: 0;
    height: 200vh;
    width: 200vw;
    background: rgba(0, 0, 0, 0.5);
    z-index: -1;
   }

   .dropdown-wrapper {
    width: min(100vw, 400px);
    background: var(--white);
    padding: var(--padding);

    display: grid;
    grid-template-rows: 1fr;
    overflow: hidden;

    overflow: hidden;
    transition: 400ms ease-in-out 200ms;

    .dropdown-wrapper-content {
     --max-height: calc(100 * var(--vh) - var(--header-height) - (var(--padding) * 2));
     max-height: var(--max-height);

     overflow: hidden;
     display: flex;
     flex-direction: column;
     align-items: flex-end;

     .close {
      transition: 350ms ease-in-out;
      height: var(--close-button-size);
      width: var(--close-button-size);

      svg {
       width: 100%;
       height: 100%;
      }

      &:hover {
       opacity: 0.5;
      }
     }

     .title {
      width: 100%;
      text-align: left;
      margin-top: 1.5rem;
     }

     .base-conditional {
      width: 100%;
      --mt: 2rem;
      margin-top: var(--mt);
      overflow: auto;
      margin-bottom: 3.25rem;
     }

     .empty-cart {
      p {
       width: 100%;
      }
     }

     .loading,
     .empty-cart {
      display: flex;
      justify-content: center;
      align-items: center;
      margin-top: 2rem;
      .mess-loading {
       --color-1: var(--red);
       --color-2: var(--gray-600);
       --color-3: var(--black);
      }
     }

     .cart-items {
      width: 100%;
      margin-top: 2rem;

      li {
       display: inline-flex;
       flex-direction: column;
       gap: 0.5rem;
       width: 100%;
       position: relative;

       + li {
        margin-top: 2rem;

        &::after {
         content: '';
         display: block;
         width: 100%;
         height: 1px;
         background: var(--gray-300);
         position: absolute;
         top: -1rem;
        }
       }
      }
     }

     .subtotal {
      display: inline-flex;
      width: 100%;
      align-items: center;
      justify-content: space-between;
      gap: 1rem;
      flex-wrap: wrap;
      margin-top: auto;
     }

     .actions {
      width: 100%;
      display: flex;
      margin-top: 1.5rem;
      justify-content: space-between;
     }
    }
   }
  }
  @media (max-width: $tablet) {
   .dropdown {
    .dropdown-wrapper {
     transition: 400ms ease-in-out;
     .dropdown-wrapper-content {
      height: var(--max-height);
     }
    }
   }
  }
 }
</style>
